import React from 'react'
import LinkListItems from './link-list-items'
import LinksListWrapper from './links-list-wrapper'

const LinksList = ({headerItems, linkItems, listTitle}) => {
  return (
    (linkItems.length) ? (
      <LinksListWrapper headerItems={headerItems}>
        {/* {listTitle && <h3 className='type-body-display-sans links-title'>{listTitle}</h3>} */}
        <LinkListItems links={linkItems} />
      </LinksListWrapper>
    ) : null
  )
}

export default LinksList
