import React from 'react'
import {encodeURL} from '../lib/string-utils'
import BlockContent from './block-content'
import ContentSection from './content-section'
import LinksList from './links-list'

const CausesList = ({causesItems}) => {
  return (
    <div className='causes-list'>
      {causesItems.length && (
        causesItems.map((cause, index) => {
          const {foregroundColour, backgroundColour, title, _rawBody, links, linksTitle} = cause
          const hash = encodeURL(title)
          return (
            <div id={encodeURL(hash)} key={index} className='cause' style={{backgroundColor: backgroundColour, color: foregroundColour}}>
              <div className='cause-title-container'><a href={`#${hash}`}><h2 className='cause-title type-body-display-sans'>{title}</h2></a></div>
              <ContentSection>
                <BlockContent blocks={_rawBody || []} />
                <LinksList linkItems={links} listTitle={linksTitle} />
              </ContentSection>
            </div>
          )
        })
      )}
    </div>
  )
}

export default CausesList
