import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import LayoutContainer from '../containers/layout'
import {getSEOMetadata} from '../lib/helpers'
import Layout from '../components/layout'
import Article from '../components/article'
import ContentSection from '../components/content-section'
import CausesList from '../components/causes-list'
import BlockContent from '../components/block-content'

export const query = graphql`
  query ActPageQuery {
    page: sanityActPage(_id: {regex: "/(drafts.|)actPage/"}) {
      title
      _rawIntro
      causes {
        title
        foregroundColour
        backgroundColour
        _rawBody(resolveReferences: {maxDepth: 10})
        linksTitle
        links {
          _key
          title
          url
          description
        }
      }
      seoFields {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const ActPage = props => {
  const {data, errors} = props
  const page = props.data.page
  const {causes, _rawIntro} = page
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <LayoutContainer>
      <SEO title={page.title} SEOMetadata={getSEOMetadata(page, '_rawIntro')} themeColour='black' />
      <Container className={'act-page'}>
        <Article>
          <div className='act-sidebar'>
            <ContentSection>
              <BlockContent blocks={_rawIntro} />
            </ContentSection>
          </div>
          <CausesList causesItems={causes} />
        </Article>
      </Container>
    </LayoutContainer>
  )
}

export default ActPage
